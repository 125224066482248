var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"mentionsListContainer",staticClass:"bg-white dark:bg-slate-800 rounded-md overflow-auto absolute w-full z-20 pb-0 shadow-md left-0 bottom-full max-h-[9.75rem] border border-solid border-slate-100 dark:border-slate-700 mention--box"},[_c('ul',{staticClass:"vertical dropdown menu"},_vm._l((_vm.items),function(item,index){return _c('woot-dropdown-item',{key:item.key,staticClass:"!mb-0",attrs:{"id":`mention-item-${index}`},on:{"mouseover":function($event){return _vm.onHover(index)}}},[_c('button',{staticClass:"flex group gap-0.5 overflow-hidden cursor-pointer rounded-none py-2.5 px-2.5 items-center w-full h-full text-left hover:bg-woot-50 dark:hover:bg-woot-800 border-x-0 border-t-0 border-b border-solid border-slate-100 dark:border-slate-700",class:{
          'bg-woot-25 dark:bg-woot-800': index === _vm.selectedIndex,
        },on:{"click":function($event){return _vm.onListItemSelection(index)}}},[_c('div',{staticClass:"flex-1 w-[calc(100%-40px)] flex flex-col"},[_c('p',{staticClass:"text-slate-900 dark:text-slate-100 group-hover:text-woot-500 dark:group-hover:text-woot-500 font-medium mb-0 text-sm overflow-hidden text-ellipsis whitespace-nowrap min-w-0 max-w-full",class:{
              'text-woot-500 dark:text-woot-500': index === _vm.selectedIndex,
            }},[_vm._v("\n            "+_vm._s(item.description)+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"text-slate-500 dark:text-slate-300 group-hover:text-woot-500 dark:group-hover:text-woot-500 mb-0 text-xs overflow-hidden text-ellipsis whitespace-nowrap min-w-0 max-w-full",class:{
              'text-woot-500 dark:text-woot-500': index === _vm.selectedIndex,
            }},[_vm._v("\n            "+_vm._s(_vm.variableKey(item))+"\n          ")])]),_vm._v(" "),(item.images && item.images.length)?_c('thumbnail',{staticClass:"flex-none",attrs:{"src":item.images[0].thumb_url,"size":"36px","txt-badge":`SL:${item.images.length}`}}):_vm._e()],1)])}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }